import React, { createContext, useState, useContext } from 'react';
import { checkAuthStatus } from 'src/services/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
    });

    const toggleIsLoggedIn = async (value) => {
        try {
            const data = await checkAuthStatus();
            if (value === true &&
                data.isAuthenticated === 'success'
            ) {
                setIsLoggedIn(true)
                localStorage.setItem('isLoggedIn', JSON.stringify(true));
            }
            else if (value === false) {
                setIsLoggedIn(false)
                localStorage.setItem('isLoggedIn', JSON.stringify(false));
            }
        } catch (error) {
            setIsLoggedIn(false)
            localStorage.setItem('isLoggedIn', JSON.stringify(false));
            console.error('Error checking authentication status:', error);
        }
    }
    return (
        <AuthContext.Provider value={{ isLoggedIn, toggleIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
