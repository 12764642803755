import axios from "axios";

// Check Authentication Status API Call
const checkAuthStatus = async () => {
    try {
        const response = await axios.get("/auth/isAuthenticated/", {
            headers: {
                Accept: "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error checking authentication status:", error);
        throw error;
    }
};

export { checkAuthStatus };
