import React, { createContext, useState } from 'react';

const RunTourContext = createContext();

const RunTourProvider = ({ children }) => {
  const [run, setRun] = useState(false);

  return (
    <RunTourContext.Provider value={{ run, setRun }}>
      {children}
    </RunTourContext.Provider>
  );
};

export { RunTourContext, RunTourProvider };
